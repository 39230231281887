export const HomeFill = ({size}) => {
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L4 10.4142V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17V10.4142L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z" fill="currentColor"/>
    </svg>
    
}
export const SearchFill = ({size}) => {
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="currentColor"/>
    </svg>
    
}
export const CalendarFill = ({size}) => {
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z" fill="currentColor"/>
    </svg>
}
export const UserFill = ({size}) => {
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9Z" fill="currentColor"/>
    <path d="M3 18C3 14.134 6.13401 11 10 11C13.866 11 17 14.134 17 18H3Z" fill="currentColor"/>
    </svg>
    
}

export const MapFill = ({size}) =>{
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 10 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.53527 1.83517C3.44884 -0.0783982 6.55135 -0.0783986 8.46492 1.83517C10.3785 3.74874 10.3785 6.85125 8.46492 8.76482L5.0001 12.2296L1.53527 8.76482C-0.378295 6.85125 -0.378295 3.74874 1.53527 1.83517ZM5.0001 6.69999C5.7733 6.69999 6.4001 6.07319 6.4001 5.29999C6.4001 4.5268 5.7733 3.89999 5.0001 3.89999C4.2269 3.89999 3.6001 4.5268 3.6001 5.29999C3.6001 6.07319 4.2269 6.69999 5.0001 6.69999Z" fill="currentColor"/>
    </svg>
}

export const PromoFill = ({size}) =>{
    return <svg  width={size ?? 18} height={size ?? 18} viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99999 0.200012C1.33725 0.200012 0.799988 0.737271 0.799988 1.40001V9.80001L2.89999 8.60001L4.99999 9.80001L7.09999 8.60001L9.19999 9.80001V1.40001C9.19999 0.737271 8.66273 0.200012 7.99999 0.200012H1.99999ZM3.49999 2.00001C3.00293 2.00001 2.59999 2.40296 2.59999 2.90001C2.59999 3.39707 3.00293 3.80001 3.49999 3.80001C3.99704 3.80001 4.39999 3.39707 4.39999 2.90001C4.39999 2.40296 3.99704 2.00001 3.49999 2.00001ZM7.22425 2.17575C6.98994 1.94143 6.61004 1.94143 6.37572 2.17575L2.77572 5.77575C2.54141 6.01006 2.54141 6.38996 2.77572 6.62428C3.01004 6.85859 3.38994 6.85859 3.62425 6.62428L7.22425 3.02428C7.45857 2.78996 7.45857 2.41006 7.22425 2.17575ZM6.49999 5.00001C6.00293 5.00001 5.59999 5.40296 5.59999 5.90001C5.59999 6.39707 6.00293 6.80001 6.49999 6.80001C6.99704 6.80001 7.39999 6.39707 7.39999 5.90001C7.39999 5.40296 6.99704 5.00001 6.49999 5.00001Z" fill="currentColor"/>
    </svg>

}

export const StarFill = ({size}) =>{
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 14 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.23924 1.14165C6.47872 0.404605 7.52145 0.404605 7.76093 1.14165L8.61659 3.77509C8.72368 4.10471 9.03085 4.32788 9.37743 4.32788H12.1464C12.9214 4.32788 13.2436 5.31957 12.6166 5.77509L10.3765 7.40264C10.0961 7.60636 9.97877 7.96745 10.0859 8.29707L10.9415 10.9305C11.181 11.6676 10.3374 12.2805 9.71045 11.8249L7.47031 10.1974C7.18992 9.99367 6.81025 9.99367 6.52986 10.1974L4.28972 11.8249C3.66275 12.2805 2.81917 11.6676 3.05865 10.9305L3.9143 8.29707C4.0214 7.96745 3.90408 7.60636 3.62369 7.40264L1.38355 5.77509C0.75658 5.31957 1.0788 4.32788 1.85378 4.32788H4.62274C4.96932 4.32788 5.27648 4.10471 5.38358 3.77509L6.23924 1.14165Z" fill="currentColor"/>
    </svg>
}

export const ArrowLeftFill = ({size}) => {
    return <svg width={size ?? 18} height={size ?? 18} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z" fill="currentColor"/>
    </svg>    
}

export const LockFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 46 52" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99998 22.8V16.4C6.99998 7.56347 14.1634 0.400024 23 0.400024C31.8365 0.400024 39 7.56347 39 16.4V22.8C42.5346 22.8 45.4 25.6654 45.4 29.2V45.2C45.4 48.7346 42.5346 51.6 39 51.6H6.99998C3.46535 51.6 0.599976 48.7346 0.599976 45.2V29.2C0.599976 25.6654 3.46535 22.8 6.99998 22.8ZM32.6 16.4V22.8H13.4V16.4C13.4 11.0981 17.698 6.80002 23 6.80002C28.3019 6.80002 32.6 11.0981 32.6 16.4Z" fill="currentColor"/>
    </svg>
}

export const WifiFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 16 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.2225 4.57746C10.7859 1.14085 5.21404 1.14085 1.77743 4.57746C1.46501 4.88988 0.958477 4.88988 0.646058 4.57746C0.333638 4.26504 0.333638 3.75851 0.646058 3.44609C4.70751 -0.615362 11.2924 -0.615362 15.3539 3.44609C15.6663 3.75851 15.6663 4.26504 15.3539 4.57746C15.0415 4.88988 14.5349 4.88988 14.2225 4.57746ZM11.9598 6.84021C9.77283 4.65327 6.22711 4.65327 4.04017 6.84021C3.72775 7.15263 3.22122 7.15263 2.9088 6.84021C2.59638 6.52779 2.59638 6.02126 2.9088 5.70884C5.72057 2.89706 10.2794 2.89706 13.0911 5.70884C13.4036 6.02126 13.4036 6.52779 13.0911 6.84021C12.7787 7.15263 12.2722 7.15263 11.9598 6.84021ZM9.69702 9.10295C8.75977 8.1657 7.24017 8.1657 6.30291 9.10295C5.99049 9.41537 5.48396 9.41537 5.17154 9.10295C4.85912 8.79053 4.85912 8.284 5.17154 7.97158C6.73364 6.40949 9.2663 6.40949 10.8284 7.97158C11.1408 8.284 11.1408 8.79053 10.8284 9.10295C10.516 9.41537 10.0094 9.41537 9.69702 9.10295ZM7.19997 10.8C7.19997 10.3582 7.55814 10 7.99997 10H8.00797C8.4498 10 8.80797 10.3582 8.80797 10.8C8.80797 11.2418 8.4498 11.6 8.00797 11.6H7.99997C7.55814 11.6 7.19997 11.2418 7.19997 10.8Z" fill="currentColor"/>
    </svg>
}

export const MoonFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 13 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8343 8.63435C12.0309 8.99767 11.139 9.19992 10.2 9.19992C6.66535 9.19992 3.79998 6.33454 3.79998 2.79992C3.79998 1.86074 4.00227 0.968817 4.36567 0.165337C2.14507 1.16954 0.599976 3.40421 0.599976 5.99977C0.599976 9.53439 3.46535 12.3998 6.99998 12.3998C9.59542 12.3998 11.83 10.8548 12.8343 8.63435Z" fill="currentColor"/>
    </svg>
}

export const SmokeFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 14 3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.333374 1.33333C0.333374 0.596954 0.930328 0 1.66671 0H11V2.66667H1.66671C0.930329 2.66667 0.333374 2.06971 0.333374 1.33333Z" fill="currentColor"/>
    <path d="M13 0C13.3682 0 13.6667 0.298477 13.6667 0.666667V2C13.6667 2.36819 13.3682 2.66667 13 2.66667V0Z" fill="currentColor"/>
    </svg>
}

export const BookingFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.79998 0.599998C3.35815 0.599998 2.99998 0.958171 2.99998 1.4V2.2H2.19998C1.31632 2.2 0.599976 2.91634 0.599976 3.8V11.8C0.599976 12.6837 1.31632 13.4 2.19998 13.4H11.8C12.6836 13.4 13.4 12.6837 13.4 11.8V3.8C13.4 2.91634 12.6836 2.2 11.8 2.2H11V1.4C11 0.958171 10.6418 0.599998 10.2 0.599998C9.75815 0.599998 9.39997 0.958171 9.39997 1.4V2.2H4.59998V1.4C4.59998 0.958171 4.2418 0.599998 3.79998 0.599998ZM3.79998 4.6C3.35815 4.6 2.99998 4.95817 2.99998 5.4C2.99998 5.84183 3.35815 6.2 3.79998 6.2H10.2C10.6418 6.2 11 5.84183 11 5.4C11 4.95817 10.6418 4.6 10.2 4.6H3.79998Z" fill="currentColor"/>
    </svg>

}

export const WarningFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4 7C13.4 10.5346 10.5346 13.4 6.99998 13.4C3.46535 13.4 0.599976 10.5346 0.599976 7C0.599976 3.46538 3.46535 0.599998 6.99998 0.599998C10.5346 0.599998 13.4 3.46538 13.4 7ZM9.78126 10.9126C8.99659 11.4713 8.03666 11.8 6.99998 11.8C4.34901 11.8 2.19998 9.65096 2.19998 7C2.19998 5.96331 2.52862 5.00338 3.08742 4.21871L9.78126 10.9126ZM10.9126 9.78117L4.21881 3.08736C5.00345 2.52862 5.96334 2.2 6.99998 2.2C9.65094 2.2 11.8 4.34903 11.8 7C11.8 8.03664 11.4714 8.99652 10.9126 9.78117Z" fill="currentColor"/>
    </svg>
}
export const ChevronRightFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fill="currentColor" />
    </svg>
}
export const ChevronLeftFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fill="currentColor" />
    </svg>
}
export const ChartPieFill = ({size,className}) => {
    return <svg className={className} width={size ?? 18} height={size ?? 18} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
    </svg>
}